ul.list-unstyled {
    margin-top: 50px;
    margin-bottom: 50px;
}

.full-height {
    min-height: 100vh;
}

@media (min-width: 768px) {
    .full-height {
        max-height: 1000px;
    }
}