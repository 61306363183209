.sponsor-logos-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.sponsor-logo-wrapper {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    padding: 1rem 5rem;
}

.sponsor-logo {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

@media (max-width: 700px) {
    .sponsor-logo {
        width: 150px;
        height: 150px;
    }
}

.sponsor-logo-spacing {
    padding-top: 40px;
    padding-bottom: 40px;
}
