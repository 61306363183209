/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #2B7871;
  border-color: #2B7871;
}

body, html {
  overflow-x: hidden;
}

.emails-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}

.email-tag {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
}

.remove-btn {
  background-color: transparent;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 0;
  margin-left: 4px;
  font-size: 16px;
}

.invalid-field {
  border-color: red ;
}