.session-dates {
    width: 80%;
}

@media (max-width: 1367px) {
    .session-dates {
        width: 50%;
    }
}

.free-spots-opf {
    color: #2B7871;
}

.free-spots-vspj {
    color: #C40B1C;
}

