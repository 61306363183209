.accordion-header-opf {
    background-color: #ffffff;
    border-bottom: #2B7871 2px solid;
    padding: 1rem 30px 1rem 1rem;
    position: relative;
    font-weight: bold;
}

.accordion-header-opf .accordion-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border-bottom: #2B7871 2px solid;
    border-right: #2B7871 2px solid;
    transition: transform 0.3s ease;
}

.accordion-header-opf .accordion-icon.plus {
    transform: translateY(-50%) rotate(45deg);
}

.accordion-header-opf .accordion-icon.minus {
    transform: translateY(-50%) rotate(-135deg);
}

.accordion-header-vspj {
    background-color: #ffffff;
    border-bottom: #C40B1C 2px solid;
    padding: 1rem;
    position: relative;
}

.accordion-header-vspj .accordion-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border-bottom: #C40B1C 2px solid;
    border-right: #C40B1C 2px solid;
    transition: transform 0.3s ease;
}

.accordion-header-vspj .accordion-icon.plus {
    transform: translateY(-50%) rotate(45deg);
}

.accordion-header-vspj .accordion-icon.minus {
    transform: translateY(-50%) rotate(-135deg);
}

.accordion-body-opf {
    padding: 1rem;
    border-bottom: #2B7871 2px solid;
}

.accordion-body-vspj {
    padding: 1rem;
    border-bottom: #C40B1C 2px solid;
}

.accordion-spacing {
    padding-top: 40px;
    padding-bottom: 40px;
}

.card {
    border: none;
    border-radius: 0;
}
