.full-width-picture-opf {
    background-image: url('../images/background-image-opf.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px; /* set the height of the picture */
    width: 100vw; /* set the width to 100 viewport width */
    margin-left: -50vw; /* center the image */
    position: relative;
    left: 50%;
}

@media (max-width: 1370px) {
    .full-width-picture-opf {
        background-image: url('../images/background-image-opf.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 230px; /* set the height of the picture */
        width: 100vw; /* set the width to 100 viewport width */
        margin-left: -50vw; /* center the image */
        position: relative;
        left: 50%;
    }
}

.full-width-picture-vspj {
    background-image: url('../images/background-image-vspj.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px; /* set the height of the picture */
    width: 100vw; /* set the width to 100 viewport width */
    margin-left: -50vw; /* center the image */
    position: relative;
    left: 50%;
}

@media (max-width: 1370px) {
    .full-width-picture-vspj {
        background-image: url('../images/background-image-vspj.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 230px; /* set the height of the picture */
        width: 100vw; /* set the width to 100 viewport width */
        margin-left: -50vw; /* center the image */
        position: relative;
        left: 50%;
    }
}

@media (max-width: 430px) {
    .full-width-picture-opf {
        background-image: url('../images/background-image-mobile-opf.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px; /* set the height of the picture */
        width: 100vw; /* set the width to 100 viewport width */
        margin-left: -50vw; /* center the image */
        position: relative;
        left: 50%;
    }

    .full-width-picture-vspj {
        background-image: url('../images/background-image-mobile-vspj.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px; /* set the height of the picture */
        width: 100vw; /* set the width to 100 viewport width */
        margin-left: -50vw; /* center the image */
        position: relative;
        left: 50%;
    }

    .text-overlay {
        position: relative;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .btn-opf{
        background-color: #fff;
        border-color: #fff;
        color: #000;
        font-size: 15px;
        padding: 8px 14px;
        text-transform: uppercase;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    }

    .btn-vspj{
        background-color: #fff;
        border-color: #fff;
        color: #000;
        font-size: 15px;
        padding: 8px 14px;
        text-transform: uppercase;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    }

    .btn-opf:hover{
        background-color: #2B7871;
        border-color: #2B7871;
        color: #fff;
        font-size: 15px;
        padding: 8px 14px;
        text-transform: uppercase;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);
    }

    .btn-vspj:hover{
        background-color: #C40B1C;
        border-color: #C40B1C;
        color: #fff;
        font-size: 15px;
        padding: 8px 14px;
        text-transform: uppercase;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);
    }
}

@media (min-width: 431px) {
    .text-overlay {
        position: relative;
        top: 75%;
        left: 53%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .btn-opf{
        background-color: #fff;
        border-color: #fff;
        color: #000;
        font-size: 22px;
        padding: 12px 23px;
        text-transform: uppercase;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    }

    .btn-vspj{
        background-color: #fff;
        border-color: #fff;
        color: #000;
        font-size: 22px;
        padding: 12px 23px;
        text-transform: uppercase;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    }

    .btn-opf:hover{
        background-color: #2B7871;
        border-color: #2B7871;
        color: #fff;
        font-size: 22px;
        padding: 12px 23px;
        text-transform: uppercase;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);
    }

    .btn-vspj:hover{
        background-color: #C40B1C;
        border-color: #C40B1C;
        color: #fff;
        font-size: 22px;
        padding: 12px 23px;
        text-transform: uppercase;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);
    }
}

.text-overlay h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    margin-right: 30px;
}

.btn-opf-registration{
    background-color: #2B7871;
    border-color: #2B7871;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    text-transform: uppercase;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

.btn-opf-registration:hover{
    background-color: #2B7871;
    border-color: #2B7871;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    text-transform: uppercase;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);
}


