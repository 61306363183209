a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  line-height: 1.1;
}

.navbar {

  text-align: left;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

}

.last-nav-link-opf {
  background-color: #2B7871;
  color: #ffffff;
}
.last-nav-link-vspj {
  background-color: #C40B1C;
  color: #ffffff;
}

.last-nav-link-opf:hover {
  background-color: #2B7871;
  color: #ffffff;
}
.last-nav-link-vspj:hover {
  background-color: #C40B1C;
  color: #ffffff;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.logo {
  height: 50px;
}
