.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    height: 200px; /* Adjust the height to your preference */
}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logo-img {
    max-width: 200px;
    max-height: 100%;
}

.component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
