/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tile {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    width: 200px;
}

.tile:hover {
    background-color: #ddd;
}

.tile.square {
    width: 300px;
    height: 300px;
}

@media ( max-width: 768px) {
    .tile.square {
        width: 300px;
        height: 150px;
    }
}