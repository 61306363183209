.slider-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 40vh;
    width: 75vw;
    border-radius: 10px;
}

.slider-content-opf {
    display: flex;
    align-items: flex-start;
    border: 1px solid #2B7871;
    box-shadow: 0 0 2rem 0.75rem rgba(43, 120, 113, 0.67);
    border-radius: 10px;
    width: 75vw;
}

.slider-content-vspj {
    display: flex;
    align-items: flex-start;
    border: 1px solid #c40b1c;
    box-shadow: 0 0 2rem 0.75rem rgba(196, 11, 28, 0.38);
    border-radius: 10px;
    width: 75vw;
}

.slider-content-left {
    margin-right: 20px;
    width: 30vw;
    height: 40vh;
    overflow: hidden;
    border-radius: 10px;
}

.slider-content-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-content-right {
    padding-top: 10px;
    padding-right: 10px;
    flex: 1;
    text-align: left;
}

.slider-button-left,
.slider-button-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
}

.slider-button {
    position: absolute;
    top: 50%;
    background: transparent;
    border: none;
    cursor: pointer;
}

.slider-button-left {
    left: 0;
    transform: translateX(-70%);
}

.slider-button-right {
    right: 0;
    transform: translateX(70%);
}

.slider-button:focus {
    outline: none;
}

.slider-content ul {
    list-style-type: none;
    padding-left: 1.2rem;
}

.slider-content li {
    position: relative;
    padding-left: 1.2rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.slider-content li::before {
    content: '•';
    font-size: 21pt;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
}

.slider-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.slider-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.slider-dot.active {
    background-color: #333;
}

.slide-transition .slider-content {
    transition: transform 0.5s ease-in-out;
    transform: translateX(0%);
}

.slider-heading {
    width: fit-content;
    padding: 10px 15px;
}

@media (max-width: 776px) {
    .slider-content-opf {
        flex-direction: column;
        align-items: center;
    }

    .slider-content-vspj {
        flex-direction: column;
        align-items: center;
    }

    .slider-content-left,
    .slider-content-right {
        width: 100%;
        margin-right: 0;
    }

    .slider-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
        width: 90vw;
        border-radius: 10px;
        margin: 0 auto 16px;
        padding: 16px;
        box-sizing: border-box;
    }

    .slider-content {
        width: 70vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .slider-content-left {
        max-width: 100%;
        height: 50%;
        overflow: hidden;
        border-radius: 10px;
    }

    .slider-content-left img {
        width: 75vw;
        aspect-ratio: 1.5;
        object-fit: cover;
        border-radius: 10px;
    }

    .slider-button-left,
    .slider-button-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .slider-button-left {
        left: 5%;
        transform: translate(-60%, -50%);
    }

    .slider-button-right {
        right: 5%;
        transform: translate(60%, -50%);
    }
}
