.gallery-button-active-opf {
    font-weight: bold;
    font-size: 1.2em; /* Adjust the font size as desired */
    color: #2B7871; /* Change the color to your preferred value */
    border-bottom: #2B7871 2px solid; /* Remove the border */
    border-right: none;
    border-left: none;
    border-top: none;
    outline: none; /* Remove the outline */
    background: none; /* Remove the background */
    padding: 5px; /* Remove any padding */
}

.gallery-button-active-vspj {
    font-weight: bold;
    font-size: 1.2em; /* Adjust the font size as desired */
    color: #C40B1C; /* Change the color to your preferred value */
    border-bottom: #C40B1C 2px solid; /* Remove the border */
    border-right: none;
    outline: none; /* Remove the outline */
    background: none; /* Remove the background */
    padding: 5px; /* Remove any padding */
}

.gallery-button {
    font-weight: normal;
    font-size: 1em; /* Adjust the font size as desired */
    color: black; /* Change the color to your preferred value */
    border: none;
    outline: none; /* Remove the outline */
    background: none; /* Remove the background */
    padding: 5px; /* Remove any padding */
}

.gallery {
    max-height: calc(100vh - 100px); /* Adjust the value (200px) as per your footer's height */
    overflow-y: auto; /* Add a scrollbar if the content exceeds the maximum height */
}

.gallery-category {
    flex-wrap: wrap;
    justify-content: space-evenly;
}


.gallery-image {
    margin: 10px;
    max-width: 300px;
}

.full-height {
    min-height: 75vh;
}

@media (min-width: 768px) {
    .full-height {
        min-height: 90vh;
    }
}

.image-title {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
}