@media (max-width: 768px) {
    .logo-footer {
        justify-content: center;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
    .last-nav-link-opf {
        background-color: #2B7871;
    }
    .last-nav-link-vspj {
        background-color: #C40B1C;
    }
}

